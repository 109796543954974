import React, { Component } from "react";

export class Magnetism extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="content">
                <div className="box">
                    <div className="box-inner">
                        <h1>{global.textContent.magnetism.title}</h1>
                    </div>
                </div>
            </div>
        )
    }

}