import React, { Component } from "react";

export class MyProfile extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="content">
                <div className="box">
                    <div className="box-inner">
                        <h1>{global.textContent.myProfile.title}</h1>

                        <p>{global.textContent.myProfile.content1}</p>
                        <p>{global.textContent.myProfile.content2}</p>
                        <p>{global.textContent.myProfile.content3}</p>
                        <p>{global.textContent.myProfile.content4}</p>
                        <p>{global.textContent.myProfile.content5}</p>
                        <p>{global.textContent.myProfile.content6}</p>
                        <p>{global.textContent.myProfile.content7}</p>
                        <p>{global.textContent.myProfile.content8}</p>
                        <p>{global.textContent.myProfile.content9}</p>
                    </div>
                </div>
            </div>
        )
    }

}