import React, { Component } from "react";
import logoContent from "../img/logoContent.webp"
import Homeimg from "../img/home.webp"

export class Home extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="home-background-image">
                <div className="mask-custom">
                    <div className="content">
                        <div className="box homeBox">
                            <div className="box-inner">
                                <div className="headerContent">
                                    <img alt="logoContent" className="logoContent" src={logoContent}></img>
                                </div>
                                <div className="homeImgContainer">
                                    <img alt="homeImg" className="homeImg" src={Homeimg}></img>
                                </div>
                                <div className="homeContentContainer">
                                    <div className="homeContent">
                                        <h5>Brigitte Rizzi Fragnière</h5>
                                        <h5>10 Grand Rue / 1443 Champvent</h5>
                                        <h5>021 525 95 73</h5>
                                        <h5>Agréée ASCA</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}