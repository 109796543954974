import React, { Component } from "react";

export class Lifting extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="content">
                <div className="box">
                    <div className="box-inner">
                        <h1>{global.textContent.lifting.title}</h1>

                        <h4 className="subtitle">{global.textContent.lifting.subtitle}</h4>
                        <p>{global.textContent.lifting.content1}</p>
                        <p>{global.textContent.lifting.content2}</p>
                        <p>{global.textContent.lifting.content3}</p>
                        <p>{global.textContent.lifting.content4}</p>
                        <table className="contentTable">
                            <tbody>
                                <tr>
                                    <td>Lieu</td>
                                    <td>{global.textContent.lifting.content5}</td>
                                </tr>
                                <tr>
                                    <td>Tél</td>
                                    <td>{global.textContent.lifting.content6}</td>
                                </tr>
                                <tr>
                                    <td>Prix</td>
                                    <td>{global.textContent.lifting.content7}</td>
                                </tr>
                                <tr>
                                    <td>Dates</td>
                                    <td>{global.textContent.lifting.content8}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

}