import React, { Component } from "react";
import Logo from "../img/logo.webp"
import $ from "jquery";
import { Link } from "react-router-dom";

import "../css/navigation.scss";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faAngleDown, faBars } from "@fortawesome/free-solid-svg-icons";
export class Navigation extends Component {

    trigger(element) {

        $(".slider:not(#" + element + ")").slideUp(400);


        var id = "#" + element;

        $(id + ".slider").slideToggle(400);
    };

    triggerCollapse() {
        $('.navigation-collapse-content').slideToggle('slow');
    }

    render() {
        return (
            <div>
                <div className="navigation-bar">
                    <Link to="/"><div className="navigation-header">
                        <img alt="logo" className="navigation-logo" src={Logo}></img>
                        <h4 className="navigation-title">Cabinet de soins</h4>
                    </div>
                    </Link>
                    <ul className="navivation-header-list-of-elements">
                        <li className="navigation-header-element"><Link to="/">Accueil</Link></li>
                        <li onClick={() => this.trigger("profil")} className="navigation-header-element">Profil<FontAwesomeIcon className="navigation-header-dropdown-icon" size="1x" icon={faAngleDown} /></li>
                        <div id="profil" className="slider">
                            <ul>
                                <Link to="/mon_profil"><li className="navigation-dropdown-element"><span className="navigation-header-element-link">Mon profil</span></li></Link>
                            </ul>
                        </div>
                        <li onClick={() => this.trigger("acuponcture")} className="navigation-header-element">Acupuncture<FontAwesomeIcon className="navigation-header-dropdown-icon" size="1x" icon={faAngleDown} /></li>
                        <div id="acuponcture" className="slider">
                            <ul>
                                <Link to="/acuponcture"><li className="navigation-dropdown-element"><span className="navigation-header-element-link">L'acupuncture</span></li></Link>
                                <Link to="/acuponcture_esthetique"><li className="navigation-dropdown-element"><span className="navigation-header-element-link">L'acupuncture esthétique</span></li></Link>
                            </ul>
                        </div>
                        <li onClick={() => this.trigger("massage")} className="navigation-header-element">Massages thérapeutiques<FontAwesomeIcon className="navigation-header-dropdown-icon" size="1x" icon={faAngleDown} /></li>
                        <div id="massage" className="slider">
                            <ul>
                                <Link to="/massage_meridiens"><li className="navigation-dropdown-element"><span className="navigation-header-element-link">Massage méridiens</span></li></Link>
                                <Link to="/massage_tuina"><li className="navigation-dropdown-element"><span className="navigation-header-element-link">Massage Tuina</span></li></Link>
                            </ul>
                        </div>
                        <li onClick={() => this.trigger("formation")} className="navigation-header-element">Formation<FontAwesomeIcon className="navigation-header-dropdown-icon" size="1x" icon={faAngleDown} /></li>
                        <div id="formation" className="slider">
                            <ul>
                                <Link to="/atelier_massage_meridien"><li className="navigation-dropdown-element"><span className="navigation-header-element-link">Massage méridiens</span></li></Link>
                            </ul>
                        </div>
                        <li onClick={() => this.trigger("atelier")} className="navigation-header-element">Atelier<FontAwesomeIcon className="navigation-header-dropdown-icon" size="1x" icon={faAngleDown} /></li>
                        <div id="atelier" className="slider">
                            <ul>
                                <Link to="/lifting"><li className="navigation-dropdown-element"><span className="navigation-header-element-link">Lifting</span></li></Link>
                            </ul>
                        </div>
                        {/* <Link to="/magnetisme"><li className="navigation-header-element">Magnétisme</li></Link> */}
                    </ul>
                    <ul className="navigation-footer-list-of-elements">
                        <li className="navigation-footer-element"><a href="https://facebook.com"><FontAwesomeIcon className="navigation-footer-element" size="3x" icon={faFacebookSquare} /> </a></li>
                    </ul>
                </div>
                <div className="navigation-collapse">
                    <FontAwesomeIcon onClick={() => this.triggerCollapse()} className="navigation-collapse-button-icon" size="2x" icon={faBars} />
                    <ul className="navigation-collapse-content">
                        <Link to="/"><li onClick={() => this.triggerCollapse()} className="navigation-header-element"><span className="navigation-collapse-item">Accueil</span></li></Link>
                        <li onClick={() => this.trigger("profil")} className="navigation-header-element"><span className="navigation-collapse-item">Profil<FontAwesomeIcon className="navigation-header-dropdown-icon" size="1x" icon={faAngleDown} /></span></li>
                        <div id="profil" className="slider">
                            <ul>
                                <Link to="/mon_profil"><li onClick={() => this.triggerCollapse()} className="navigation-dropdown-element"><span className="navigation-header-element-link">Mon profil</span></li></Link>
                            </ul>
                        </div>
                        <li onClick={() => this.trigger("acuponcture")} className="navigation-header-element"><span className="navigation-collapse-item">Acupuncture<FontAwesomeIcon className="navigation-header-dropdown-icon" size="1x" icon={faAngleDown} /></span></li>
                        <div id="acuponcture" className="slider">
                            <ul>
                                <Link to="/acuponcture"><li onClick={() => this.triggerCollapse()} className="navigation-dropdown-element"><span className="navigation-header-element-link">L'acupuncture</span></li></Link>
                                <Link to="/acuponcture_esthetique"><li onClick={() => this.triggerCollapse()} className="navigation-dropdown-element"><span className="navigation-header-element-link">L'acupuncture esthétique</span></li></Link>
                            </ul>
                        </div>
                        <li onClick={() => this.trigger("massage")} className="navigation-header-element"><span className="navigation-collapse-item">Massages thérapeutiques<FontAwesomeIcon className="navigation-header-dropdown-icon" size="1x" icon={faAngleDown} /></span></li>
                        <div id="massage" className="slider">
                            <ul>
                                <Link to="/massage_meridiens"><li onClick={() => this.triggerCollapse()} className="navigation-dropdown-element"><span className="navigation-header-element-link">Massage méridiens</span></li></Link>
                                <Link to="/massage_tuina"><li onClick={() => this.triggerCollapse()} className="navigation-dropdown-element"><span className="navigation-header-element-link">Massage Tuina</span></li></Link>
                            </ul>
                        </div>
                        <li onClick={() => this.trigger("formation")} className="navigation-header-element"><span className="navigation-collapse-item">Formation<FontAwesomeIcon className="navigation-header-dropdown-icon" size="1x" icon={faAngleDown} /></span></li>
                        <div id="formation" className="slider">
                            <ul>
                                <Link to="/atelier_massage_meridien"><li onClick={() => this.triggerCollapse()} className="navigation-dropdown-element"><span className="navigation-header-element-link">Massage méridiens</span></li></Link>
                            </ul>
                        </div>
                        <li onClick={() => this.trigger("atelier")} className="navigation-header-element"><span className="navigation-collapse-item">Atelier<FontAwesomeIcon className="navigation-header-dropdown-icon" size="1x" icon={faAngleDown} /></span></li>
                        <div id="atelier" className="slider">
                            <ul>
                                <Link to="/lifting"><li onClick={() => this.triggerCollapse()} className="navigation-dropdown-element"><span className="navigation-header-element-link">Lifting</span></li></Link>
                            </ul>
                        </div>
                        {/* <Link to="/magnetisme"><li onClick={() => this.triggerCollapse()} className="navigation-header-element"><span className="navigation-collapse-item">Magnétisme</span></li></Link> */}
                    </ul>
                </div >
            </div >
        )

    }

}
