import React, { Component } from "react";

export class AcupunctureAesthetic extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render(){
        return(
            <div className="content">
                <div className="box">
                    <div className="box-inner">
                        <h1 className="acupuncture-title">{global.textContent.acupunctureAesthetic.title}</h1>
                        <p className="acupuncture-subtitle">{global.textContent.acupunctureAesthetic.subtitle}</p>

                        <p>{global.textContent.acupunctureAesthetic.content1}</p>
                        <p>{global.textContent.acupunctureAesthetic.content2}</p>
                        <p>{global.textContent.acupunctureAesthetic.content3}</p>
                        <p>{global.textContent.acupunctureAesthetic.content4}</p>
                        <ul>
                            <li>{global.textContent.acupunctureAesthetic.content5}</li>
                            <li>{global.textContent.acupunctureAesthetic.content6}</li>
                        </ul>
                        <p>{global.textContent.acupunctureAesthetic.content7}</p>
                        <p>{global.textContent.acupunctureAesthetic.content8}</p>
                        <p className="bigger">{global.textContent.acupunctureAesthetic.content9}</p>
                    </div>
                </div>
            </div>
        )
    }

}