import React, { Component } from "react";

export class MassageTuina extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="content">
                <div className="box">
                    <div className="box-inner">
                        <h1>{global.textContent.massageTuina.title}</h1>

                        <p>{global.textContent.massageTuina.content1}</p>
                        <p>{global.textContent.massageTuina.content2}</p>
                        <p>{global.textContent.massageTuina.content3}</p>
                    </div>
                </div>
            </div>
        )
    }

}