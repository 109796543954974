import React, { Component } from "react";

export class Acupuncture extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render(){
        return(
            <div className="content">
                <div className="box">
                    <div className="box-inner">
                        <h1>{global.textContent.acupuncture.title}</h1>

                        <p>{global.textContent.acupuncture.content1}</p>
                        <p>{global.textContent.acupuncture.content2}</p>
                        <p>{global.textContent.acupuncture.content3}</p>
                        <p>{global.textContent.acupuncture.content4}</p>
                        <p>{global.textContent.acupuncture.content5}</p>
                        <p>{global.textContent.acupuncture.content6}</p>
                    </div>
                </div>
            </div>
        )
    }

}