import React, { Component } from "react";

export class MassageMeridianWorkshop extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="content">
                <div className="box">
                    <div className="box-inner">
                        <h1>{global.textContent.massageMeridianWorkshop.title}</h1>

                        <h2 className="subtitle">{global.textContent.massageMeridianWorkshop.subtitle}</h2>
                        <p>{global.textContent.massageMeridianWorkshop.content1}</p>
                        <p>{global.textContent.massageMeridianWorkshop.content2}</p>
                        <p>{global.textContent.massageMeridianWorkshop.content3}</p>
                        <p>{global.textContent.massageMeridianWorkshop.content4}</p>
                        <p>{global.textContent.massageMeridianWorkshop.content5}</p>
                        <table className="contentTable">
                            <tbody>
                                <tr>
                                    <td>Lieu</td>
                                    <td>{global.textContent.massageMeridianWorkshop.content6}</td>
                                </tr>
                                <tr>
                                    <td>Tél</td>
                                    <td>{global.textContent.massageMeridianWorkshop.content7}</td>
                                </tr>
                                <tr>
                                    <td>Horaire</td>
                                    <td>{global.textContent.massageMeridianWorkshop.content8}</td>
                                </tr>
                                <tr>
                                    <td>Prix</td>
                                    <td>{global.textContent.massageMeridianWorkshop.content9}</td>
                                </tr>
                                <tr>
                                    <td>Dates</td>
                                    <td>{global.textContent.massageMeridianWorkshop.content10}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

}