module.exports = global.textContent = {
    myProfile: {
        title: "Mon profil",
        content1: "Thérapeute en massage énergétique depuis 25 ans, diplômée de l’académie de médecine traditionnelle chinoise de Pékin en 2001, avec stage en Chine en milieu hospitalier et en cabinet médical privé.",
        content2: "Je pratique le massage des méridiens et des points d’acupuncture, le massage chinois (tuina), l'acupuncture, l’acupuncture esthétique, le massage relaxant et les soins énergétiques.",
        content3: "En séance, je perçois la souffrance du patient, ses douleurs physiques, ses tensions émotionnelles et mentales.",
        content4: "Toutes ces indications me guident vers la provenance de ces maux, me signalent les zones à traiter, à soulager, les méridiens à libérer et les points d’acupression à travailler. J'accomplis mon travail en conscience tant au niveau du ressenti qu'avec mes connaissances professionnelles acquises durant de nombreuses années.",
        content5: "L’énergie universelle m'offre la faculté de soulager et d’aider ceux qui souffrent.",
        content6 :"Honorons ce merveilleux cadeau et transmettons-le à celui qui en à besoin.",
        content7 :"",
        content8 :"",
        content9 :""
    },
    acupuncture :{
        title:"L’acupuncture",
        content1: "L’acupuncture est une technique de soins naturels, une des branches de la médecine traditionnelle Chinoise.",
        content2: "Elle est utilisée de manière thérapeutique au même titre que le massage des méridiens et des points d’acupuncture et le Tuina, pour maintenir, harmoniser et renforcer l’équilibre énergétique du corps, par la stimulation des points situés sur l’ensemble de celui-ci à l’aide d’aiguilles.",
        content3: "Le principe est simple. Le qi (énergie), le sang et les liquides doivent circuler librement, de  manière fluide et constant dans les méridiens, afin de nourrir et renforcer les organes, les os, les muscles, les tendons et la peau.",
        content4: "Si un excès ou un vide de qi, se manifeste, l’organisme sera en déséquilibre et la maladie s’installera.",
        content5: "La médecine chinoise participe, au maintien de notre santé.",
        content6: "Séance : 60 minutes  /  CHF 120.-",
    },
    acupunctureAesthetic :{
        title:"L’acupuncture esthétique",
        subtitle: "Lifting du visage",
        content1: "L’acupuncture esthétique est une méthode naturelle ayant pour but de tonifier la musculature, raffermir l’épiderme, diminuer la profondeur des rides, retarder leurs formations, remodeler l’ovale du visage et raviver l’éclat du teint.",
        content2: "Un soin indispensable pour prévenir le vieillissement, atténuer et retarder les effets de l’âge, afin de conserver son capital jeunesse. Le soin est adapté au besoin de chacun.",
        content3: "Il est suggéré 6 à 10 séances rapprochées, à raison d’une fois par semaine en cabinet. Puis, selon l’âge et l’état de la peau, un entretien régulier est préconisé.",
        content4: "Une séance comprend:",
        content5: "L’acupuncture sur le visage et sur le corps.",
        content6: "Un massage spécifique visant à corriger les imperfections indésirables (rides, peau atone).",
        content7: "Afin de perdurer le soin en cabinet, il est fortement conseiller de pratiquer quotidiennement à la maison, les exercices effectués lors de chaque séance.",
        content8: "Séance : 75 minutes   /   CHF 170.-",
        content9: "Atelier : Lifting naturel du visage : Comment régénérer et conserver son capital jeunesse"
    },
    massageMeridian :{
        title:"Massage des méridiens et des points d'acunpuncture",
        content1: "Basé sur le concept de la médecine traditionnelle Chinoise, ce massage thérapeutique est un soin profond et intégral, travaillant sur les méridiens et sur leurs points d’acupuncture.",
        content2: "Il permet de rééquilibrer le flux énergétique dans tout l’organisme, harmonisant le yin et le yang des organes et des viscères, indispensable au bon fonctionnement et au maintien de la santé du corps dans son entier.",
        content3: "Tout en renforçant les systèmes : respiratoire, circulatoire et digestif, il assure également une action fortifiante et réparatrice sur les os, les muscles, les tendons et les tissus.",
        content4: "Un merveilleux soin aux résultats immédiat.",
        content5: "En agissant sur la globalité de l’être humain, toutes tensions, toutes douleurs physiques, émotionnelles et mentales sont libérées et soulagées, autorisant ainsi le corps à retrouver un esprit calme et serein.",
        content6: "Un lien puissant unit l’esprit et le corps. Un esprit qui est en paix avec lui-même, maintien le corps en bonne santé.",
        content7: "Tulku Thondup",
        content8: "Savoir restituer l’harmonie dans le système des méridiens, permet de guérir plus aisément les maladies.",
        content9: "Indications :",
        content10: "Douleurs épaules, genoux, douleurs dorsales, arthrite, lumbago, sciatique.",                                              
        content11: "Migraine, insomnie, fatigue  chronique, déprime, stress.",                                              
        content12: "Ménopause, douleurs menstruelles, problème circulatoire.",
        content13: "Cystite, incontinence.",
        content14: "Allergie, rhume des foins.",
        content15: "Bronchite, toux, sinusite, rhinite.",
        content16: "Gastrite, constipation, ballonnement.",
        content17: "Renforcement du système immunitaire, etc.",
        content18: "Ce soin remplace efficacement les séances d’acupuncture, pour ceux qui craignent les aiguilles.",
        content19: "Séance : 75 minutes   /   CHF 120.-",
    },
    massageTuina :{
        title:"Massage Tuina",
        content1: "Méthode comprenant diverses manipulations et techniques sur les	méridiens et sur des points précis du corps, provoquant des réactions locales ou générales, de manière à régulariser les fonctions de l’organisme.",
        content2: "Ces différentes techniques sont : Percussion, pression, friction, saisir, pousser, rouler, pétrir. Ayant pour but la prévention et pour traiter certaines maladies.",
        content3: "Séance : 60 minutes  /  CHF 120.-",
    },
    massageMeridianWorkshop :{
        title:"Massage des méridiens et des points d'acupuncture",
        subtitle: "Gérer soi-même son capital santé",
        content1: "Approche simple, méthode naturelle, facile et efficace, s’effectuant sur le trajet des méridiens et sur leurs points d’acupuncture. Apportant des résultats remarquables, tant sur le plan de la santé et le bien-être (CF à massage des méridiens et des points d’acupuncture) que sur celui de l’esthétique.",
        content2: "Un soin merveilleux aux résultats immédiats à pratiquer sur soi, sur ses enfants ou sur toutes autres personnes, à titre thérapeutique ou tout simplement à titre préventif.",
        content3: "Apprendre à rétablir l’harmonie dans le système des méridiens, permet de guérir plus aisément les maladies.",
        content4: 'De plus, effectué régulièrement sur soi, il dissout de façon "magique" et rapide les imperfections disgracieuses, telles que : peau atone (flasque), ventre gonflé, etc.',
        content5: "Aucun prérequis n’est demandé pour ce cours.",
        content6: "10 Grand Rue   /   1443 Champvent",
        content7: "021.525.95.73",
        content8: "9h00 – 12h00 et 13h00 – 18h00",
        content9: "CHF 170.- par jour de cours, soit CHF 1'190.- pour 7 cours. Supports de cours à l’appui",
        content10: "Me contacter"
    },
    lifting :{
        title: "Lifting naturel du visage",
        subtitle: "Comment régénérer et conserver son capital jeunesse",
        content1: 'Cet atelier repose sur l’étude de points d’acupuncture spécifiques "beauté" ainsi que de points clefs "raffermissement" et "anti-rides".',
        content2: "En complément, vient s’ajouter une technique de massage ciblée, indispensable, afin de consolider la pratique des points, en s’appuyant sur une méthode simple et efficace.",
        content3: "Les résultats sont rapides et visibles dès les premières séances. Ovale du visage remodelé bajoues éliminées, double menton diminué, rides lissées, lèvres repulpées, paupières raffermies.",
        content4: "Supports de cours à l’appui",
        content5: "10 Grand Rue   /   1443 Champvent",
        content6: "021.525.95.73",
        content7: "1 journée et 1 matinée : CHF 310.-",
        content8: "Me contacter"
    }
    ,
    magnetism :{
        title: "Magnétisme",
    }
};