import React, { Component } from "react";

export class MassageMeridian extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="content">
                <div className="box">
                    <div className="box-inner">
                        <h1>{global.textContent.massageMeridian.title}</h1>

                        <p>{global.textContent.massageMeridian.content1}</p>
                        <p>{global.textContent.massageMeridian.content2}</p>
                        <p>{global.textContent.massageMeridian.content3}</p>
                        <p>{global.textContent.massageMeridian.content4}</p>
                        <p>{global.textContent.massageMeridian.content5}</p>
                        <div className="row">
                            <div className="citationAndAuthor">
                                <blockquote className="citation">
                                    <q>{global.textContent.massageMeridian.content6}</q>
                                    <p className="author">{global.textContent.massageMeridian.content7}</p>
                                </blockquote>
                            </div>
                        </div>
                        <p>{global.textContent.massageMeridian.content8}</p>
                        <p>{global.textContent.massageMeridian.content9}</p>
                        <ul>
                            <li>{global.textContent.massageMeridian.content10}</li>
                            <li>{global.textContent.massageMeridian.content11}</li>
                            <li>{global.textContent.massageMeridian.content12}</li>
                            <li>{global.textContent.massageMeridian.content13}</li>
                            <li>{global.textContent.massageMeridian.content14}</li>
                            <li>{global.textContent.massageMeridian.content15}</li>
                            <li>{global.textContent.massageMeridian.content16}</li>
                            <li>{global.textContent.massageMeridian.content17}</li>
                        </ul>
                        <p>{global.textContent.massageMeridian.content18}</p>
                        <p>{global.textContent.massageMeridian.content19}</p>
                    </div>
                </div>
            </div>
        )
    }

}