//Components
import { Navigation } from "./component/Navigation";
//Pages
import { Acupuncture } from "./page/Acupuncture";
import { AcupunctureAesthetic } from "./page/AcupunctureAesthetic";
import { Lifting } from "./page/Lifting";
import { Magnetism } from "./page/Magnetism";
import { MassageMeridian } from "./page/MassageMeridian";
import { MassageMeridianWorkshop } from "./page/MassageMeridianWorkshop";
import { MassageTuina } from "./page/MassageTuina";
import { Home } from "./page/Home";
import { MyProfile } from "./page/MyProfile";
//React-router
import { Routes, Route } from "react-router-dom";
//CSS
import "./css/global.scss";
import "./css/content.scss"

function App() {
  return (
    <div className="App">
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mon_profil" element={<MyProfile />} />
        <Route path="/acuponcture" element={<Acupuncture />} />
        <Route path="/acuponcture_esthetique" element={<AcupunctureAesthetic />} />
        <Route path="/massage_meridiens" element={<MassageMeridian />} />
        <Route path="/massage_tuina" element={<MassageTuina />} />
        <Route path="/atelier_massage_meridien" element={<MassageMeridianWorkshop />} />
        <Route path="/lifting" element={<Lifting />} />
        <Route path="/magnetisme" element={<Magnetism />} />
      </Routes>
    </div>
  );
}

export default App;
